import React, { FC, ReactElement } from "react";
import styles from "./Tag.module.scss";

type TagProps = {
	children: React.ReactChild;
};

type TagsProps = {
	tags?: Array<string>;
};

export const Tag: FC<TagProps> = ({ children }): ReactElement => (
	<li className={styles.tag}>{children}</li>
);

export const Tags: FC<TagsProps> = ({ tags, children }): ReactElement => (
	<ul className={styles.tags} aria-label="Item tags">
		{!tags ? children : tags.map((tag) => <Tag key={tag}>{tag}</Tag>)}
	</ul>
);
