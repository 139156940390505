import React, { FC } from "react";
import { graphql, PageProps, Link } from "gatsby";
import classnames from "classnames";
import { readingTime } from "reading-time-estimator";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { Layout } from "../components/Layout/Layout";
import { PartialPost, PartialPostData } from "../types/types";
import { SEO } from "../components/SEO/SEO";
import { Container } from "../components/Container/Container";
import { CustomPrismicLink } from "../components/CustomPrismicLink/CustomPrismicLink";
import { Tags } from "./../components/Tag/Tag";
import { Callout } from "../components/Callout/Callout";
import { Breadcrumbs, Breadcrumb } from "@nice-digital/nds-breadcrumbs";

import styles from "./Post.module.scss";
import { Share } from "../components/Share/Share";

export type PostPageProps = PageProps<
	{
		prismicPost: PartialPost & {
			data: PartialPostData & {
				category: string;
				content: {
					richText: RichTextBlock[];
					text: string;
				};
				seo_title: string;
				seo_description: string;
			};
		};
	},
	{
		uid: string;
	}
>;

const PostPage: FC<PostPageProps> = ({
	data: {
		prismicPost: {
			// first_publication_date,
			// first_publication_date_formatted,
			tags,
			data: {
				title,
				summary,
				image: { alt, fluid },
				seo_title,
				seo_description,
				author,
				content: { richText, text },
			},
		},
	},
}) => {
	const { minutes } = readingTime(text, 250);
	return (
		<Layout>
			<SEO
				title={seo_title || title}
				description={seo_description || summary}
			/>
			<article>
				<header className={styles.header}>
					<Container>
						<Breadcrumbs className={styles.breadcrumbs}>
							<Breadcrumb elementType={Link} to="/resource-centre">
								Resource Centre
							</Breadcrumb>
							{/* <Breadcrumb>{title}</Breadcrumb> */}
						</Breadcrumbs>
						<h1 className={styles.heading}>{title}</h1>

						<div className={styles.info}>
							<div className={styles.left}>
								<GatsbyImage image={fluid} alt={alt} className={styles.image} />
							</div>
							<div className={styles.right}>
								<dl
									className={styles.metadata}
									aria-label="Author, date and reading time information"
								>
									<dt className="visuallyHidden">Author</dt>
									<dd className={styles.byline}>
										<span aria-hidden="true">By</span>{" "}
										{author.document.data.name}
									</dd>
									<dt className="visuallyHidden">Reading time</dt>
									<dd className={styles.byline}>~ {minutes} min read</dd>
									{tags.length > 0 && (
										<>
											<dt className="visuallyHidden">Article tags</dt>
											<dd className={styles.fullWidthTags}>
												<Tags tags={tags} />
											</dd>
										</>
									)}
									{/* TODO: we need date here? */}
									{/* <dt className="visuallyHidden">Published date</dt>
									<dd>
										<time
											className={styles.date}
											dateTime={first_publication_date}
										>
											{first_publication_date_formatted}
										</time>
									</dd> */}
								</dl>

								{summary && <p className={styles.lead}>{summary}</p>}
								<Share title={title} />
							</div>
						</div>
					</Container>
				</header>

				<Container isNarrow className={styles.content}>
					<RichText render={richText} serializeHyperlink={CustomPrismicLink} />
				</Container>

				<Container elementType="footer" isNarrow className={styles.footer}>
					<div className={styles.parent}>
						<GatsbyImage
							className={styles.authorImage}
							image={author.document.data.avatar.fluid}
							alt=""
						/>
						<div>
							<Share title={title} className={styles.share} />
							<dl className={styles.metadata} aria-label="Author information">
								<dt className={styles.byline}>Written by</dt>
								<dd className={styles.byline}>{author.document.data.name}</dd>
							</dl>
							<dl className={classnames(styles.metadata, styles.second)}>
								<dt className="visuallyHidden">Author bio</dt>
								<dd>{author.document.data.bio}</dd>
							</dl>
						</div>
					</div>
				</Container>
			</article>
			<Callout />
		</Layout>
	);
};

export default PostPage;

export const pageQuery = graphql`
	query PostQuery($uid: String!) {
		prismicPost(uid: { eq: $uid }) {
			...PartialPost
			data {
				...PartialPostData
				category
				content {
					richText
					text
				}
				seo_title
				seo_description
			}
		}
	}
`;
