import React, { FC, ElementType } from "react";
import { useLocation } from "@reach/router";
import classNames from "classnames";

import { useSiteMetadata } from "../../hooks/use-site-metadata";
import FacebookIcon from "../../assets/jam-icons/facebook.svg";
import TwitterIcon from "../../assets/jam-icons/twitter.svg";
import LinkedInIcon from "../../assets/jam-icons/linkedin.svg";

import styles from "./Share.module.scss";

export interface ShareProps {
	title: string;
	/** Leave the URL blank to default to the current page URL */
	url?: string;
	summary?: string;
	isDark?: boolean;
	className?: string;
}

interface ShareLinkIconProps {
	url: string;
	icon: ElementType;
	children: string;
	isDark?: boolean;
}

const ShareLinkIcon: FC<ShareLinkIconProps> = ({
	url,
	children,
	icon: IconElementType,
}) => (
	<li>
		<a
			className={styles.link}
			target="_blank"
			rel="noopener noreferrer"
			href={url}
		>
			<IconElementType
				className={styles.icon}
				focusable="false"
				aria-hidden={true}
			/>
			<span className="visuallyHidden">{children}</span>
		</a>
	</li>
);

export const Share: FC<ShareProps> = ({
	url,
	title,
	summary,
	isDark,
	className,
}) => {
	const { pathname } = useLocation(),
		{ siteUrl, twitterUsername } = useSiteMetadata(),
		encodedSiteUrl = encodeURIComponent(siteUrl),
		encodedPageUrl = encodeURIComponent(url || siteUrl + pathname),
		encodedTitle = encodeURIComponent(title),
		encodedSummary = summary ? encodeURIComponent(summary) : "";

	return (
		<ul
			className={classNames(styles.list, className, {
				[styles.isDark]: isDark,
			})}
			aria-label="Share links for social media"
		>
			<ShareLinkIcon
				icon={FacebookIcon}
				url={`https://www.facebook.com/sharer/sharer.php?u=${encodedPageUrl}&t=${encodedTitle}`}
			>
				Share on Facebook
			</ShareLinkIcon>
			<ShareLinkIcon
				icon={TwitterIcon}
				url={`https://twitter.com/intent/tweet?url=${encodedPageUrl}&via=${twitterUsername}&text=${encodedTitle}`}
			>
				Share on Twitter
			</ShareLinkIcon>
			<ShareLinkIcon
				icon={LinkedInIcon}
				url={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedPageUrl}&title=${encodedTitle}&summary=${encodedSummary}&source=${encodedSiteUrl}`}
			>
				Share on LinkedIn
			</ShareLinkIcon>
		</ul>
	);
};
